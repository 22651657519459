<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 login-form login-signin">
        <div class="text-center mb-10 mb-lg-10">
          <h3 class="font-size-h1">Link sunbed</h3>
          <p class="text-muted font-weight-semi-bold">
            Please select an option to link <strong>{{ form.serial }}</strong> to your company.
          </p>
        </div>

        <!--begin::Form-->
        <form class="form">
          <div class="row">
            <div class="col-12">
              <label class="option h-100 d-block d-md-flex">
                <div class="option-label my-auto">
                  <span class="option-head">
                    <span class="option-title">Link to my company</span>
                  </span>
                  <span class="option-body my-auto">Choose this option if you already have an owner-type company and would like to link this sunbed to your company.</span>
                </div>

                <div class="option-control w-auto mt-3 my-md-auto">
                  <button type="button" class="btn btn-info btn-block w-100px ml-auto" @click.prevent="onAuthButton">Continue</button>
                </div>
              </label>
            </div>
          </div>

          <div class="w-100 my-3 text-center text-muted font-weight-semi-bold">OR</div>

          <div class="row mt-3">
            <div class="col-12">
              <label class="option h-100 d-block d-md-flex">
                <div class="option-label my-auto">
                  <span class="option-head">
                    <span class="option-title">Sign up</span>
                  </span>
                  <span class="option-body my-auto">Choose this option if you don’t have an account yet, or would like to create an owner-type company.</span>
                </div>

                <div class="option-control w-auto mt-3 my-md-auto">
                  <button type="button" class="btn btn-info btn-block w-100px ml-auto" @click.prevent="onSignupButton">Sign up</button>
                </div>
              </label>
            </div>
          </div>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <router-link :to="{ path: '/login', query: { email: (form.email || undefined) }}" class="text-dark-60 text-hover-primary my-3 mr-2">
              Back to login
            </router-link>
          </div>
          <!--end::Action-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';

export default {
  mixins: [validationMixin],
  name: 'linkSunbed',
  data () {
    return {
      alert: false,
      form: {
        serial: null,
        psk: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
    },
  },

  async mounted () {
    // Set serial and psk based on query
    if (this.$route.query) {
      this.$set(this.form, 'serial', this.$route.query.id || null);
      this.$set(this.form, 'psk', this.$route.query.psk || null);
    }

    await this.$nextTick();
  },

  methods: {
    async onAuthButton () {
      // Check if authenticated
      try {
        await this.$auth.checkAuthenticated();
      } catch (e) {
        // Don't error, just continue
      }

      // If authenticated, redirect to sunbed overview
      if (this.$auth.isAuthenticated() === true) {
        this.$router.push({
          path: '/sunbeds/overview',
          query: {
            link: `${this.form.serial}${(this.form.psk ? `:${this.form.psk}` : '')}`,
          },
        });
        return;
      }

      // If not authenticated, redirect to login page
      this.$router.push({
        path: '/login',
        query: {
          redirect_uri: `/sunbeds/overview?link=${this.form.serial}${(this.form.psk ? `:${this.form.psk}` : '')}`,
        },
      });
    },

    onSignupButton () {
      this.$router.push({
        path: '/sign-up',
        query: {
          module: this.form.serial,
          module_psk: this.form.psk,
        },
      });
    },
  },
};
</script>
